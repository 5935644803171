var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.toggle.customerClearance
    ? _c(
        "div",
        { staticClass: "px-3 border-bottom" },
        [
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_total_lab_quarantine_fee"),
                prop: "totalQuarantineFee",
                "label-col": _vm.labelCol
              }
            },
            [
              _c("span", { staticClass: "mr-2" }, [
                _vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))
              ]),
              _c("span", [
                _vm._v(_vm._s(_vm._f("currency")(_vm.form.totalQuarantineFee)))
              ])
            ]
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_tax_fee"),
                prop: "taxFee",
                "label-col": _vm.labelCol
              }
            },
            [
              _c("span", { staticClass: "mr-2" }, [
                _vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))
              ]),
              _c("span", [_vm._v(_vm._s(_vm._f("currency")(_vm.form.taxFee)))])
            ]
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_total_warehouse_rent"),
                prop: "totalWarehouseRent",
                "label-col": _vm.labelCol
              }
            },
            [
              _c("span", { staticClass: "mr-2" }, [
                _vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))
              ]),
              _c("span", [
                _vm._v(_vm._s(_vm._f("currency")(_vm.form.totalWarehouseRent)))
              ])
            ]
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_total_unloading_cost"),
                prop: "totalUnloadingCost",
                "label-col": _vm.labelCol
              }
            },
            [
              _c("span", { staticClass: "mr-2" }, [
                _vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))
              ]),
              _c("span", [
                _vm._v(_vm._s(_vm._f("currency")(_vm.form.totalUnloadingCost)))
              ])
            ]
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_document_fee"),
                prop: "totalDocumentFee",
                "label-col": _vm.labelCol
              }
            },
            [
              _c("span", { staticClass: "mr-2" }, [
                _vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))
              ]),
              _c("span", [
                _vm._v(_vm._s(_vm._f("currency")(_vm.form.totalDocumentFee)))
              ])
            ]
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_ppjk_edi"),
                prop: "ppjkEdi",
                "label-col": _vm.labelCol
              }
            },
            [
              _c("span", { staticClass: "mr-2" }, [
                _vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))
              ]),
              _c("a-input-number", {
                staticStyle: { width: "170px" },
                attrs: {
                  value: _vm.form.ppjkEdi,
                  formatter: _vm.formatterNumber,
                  parser: _vm.reverseFormatNumber,
                  precision: _vm.DECIMAL_PLACES_QTY,
                  min: 0,
                  placeholder: _vm.$t("lbl_type_here"),
                  readonly: _vm.isUnbilled
                },
                on: {
                  change: function(e) {
                    return _vm.commitState(e, "ppjkEdi")
                  }
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_stamp_duty_etc"),
                prop: "stampDuty",
                "label-col": _vm.labelCol
              }
            },
            [
              _c("span", { staticClass: "mr-2" }, [
                _vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))
              ]),
              _c("a-input-number", {
                staticStyle: { width: "170px" },
                attrs: {
                  value: _vm.form.stampDuty,
                  formatter: _vm.formatterNumber,
                  parser: _vm.reverseFormatNumber,
                  precision: _vm.DECIMAL_PLACES_QTY,
                  min: 0,
                  placeholder: _vm.$t("lbl_type_here"),
                  readonly: _vm.isUnbilled
                },
                on: {
                  change: function(e) {
                    return _vm.commitState(e, "stampDuty")
                  }
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_total_ppjk"),
                prop: "totalPpjk",
                "label-col": _vm.labelCol
              }
            },
            [
              _c("span", { staticClass: "mr-2" }, [
                _vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))
              ]),
              _c("span", [
                _vm._v(_vm._s(_vm._f("currency")(_vm.form.totalPpjk)))
              ])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }